<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #tail>
        <ly-button @click="newAdd" class="m-l1" type="primary" style="background: #396AFC;border-color:#396AFC" :loading="false">
          <span style="margin-right: 3px;">+</span>新增司机
        </ly-button>
      </template>
    </ly-searchList>
    <div class="tableBox">
      <ly-table :columns="columns" ref="tableRef" size="small" :data="data" :loading="loading" :pagination="pagination" @pageChange="pageChange">
        <template #lookImg="{ record }">
          <a v-if="record?.fileIds?.length > 0" @click="lookImg(record)">查看</a>
          <span v-else>无</span>
        </template>
        <template #add="{ record }">
          <a class="m-r2" @click="openItem(record)">编辑</a>
          <span class="color-R cur-p" @click="delItem(record)">删除</span>
        </template>
      </ly-table>
    </div>
    <ly-modal v-model:visible="visible" :title="title" :footer="false">
      <ly-formList v-if="visible" ref="formListRef" :fromList="fromList" @cancel="cancel" @getVal="getVal">
        <template #custom>
          <div class="m-t1">司机证件照片<span style="font-size:12px;color:#999">(驾驶证、货运资格证)</span></div>
          <div class=" m-t1  ">
            <ly-upload :maxNum="10" ref="upload1" text="上传照片"></ly-upload>
          </div>
        </template>
      </ly-formList>
    </ly-modal>
    <ly-modal v-model:visible="delVisible" title="系统提示" delText="确定" @handleOk="handleOk" :loading="delLoading" :isTips="true">
      <p>确认要删除吗？</p>
    </ly-modal>
  </div>
</template>
  
  <script setup>
import { onMounted, ref } from 'vue'
import { iptData, columns, fromList } from './driverList.js'
import { driverAdd, driverEdit, driverPage, driverInfo, driverDelete } from '@/api/crmManagement/crm'
import { cloneDeep } from 'lodash-es';
import { message } from 'ant-design-vue';
const props = defineProps({
  id: {
    type: String,
    required: true
  }
})
const emit = defineEmits(['lookImg'])
const formListRef = ref(null)
const delVisible = ref(false)
const editID = ref('')
const isEdit = ref(false)
const loading = ref(false)
const visible = ref(false)
const title = ref('')
const data = ref([])
const searchMsg = ref({})
const upload1 = ref(false)
const delLoading = ref(false)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqDriverPage()
}
//查看图片
const lookImg = (record) => {
  emit('lookImg', record.fileIds)
}
//分页事件
const pageChange = (e) => {
  pagination.value = e
  reqDriverPage()
}
//删除
const delItem = (record) => {
  editID.value = record.id
  delVisible.value = true
}
const handleOk = () => {
  delLoading.value = true
  driverDelete({carrierDriverId:editID.value}).then(res => {
    if (res.code !== 10000) return
    if(data.value.length === 1 && pagination.value.current > 1){
      pagination.value.current --
    }
    delVisible.value = false
    reqDriverPage()
    message.success(res.msg)
  }).finally(() => {
    delLoading.value = false
  })
}
//编辑
const openItem = (item) => {
  driverInfo(item.id).then((res) => {
    if(res.code !== 10000) return
    title.value = '编辑司机'
    isEdit.value = true
    editID.value = item.id
    fromList.value.forEach( opt => {
        opt.value = res.data[opt.prop]
    })
    visible.value = true
    const time = setTimeout(() => {
      upload1.value.state.fileIds = res.data.files ? res.data.files : []
      clearTimeout(time)
    }, 300)
  })
}
// 搜索事件
const searchAdd = (e) => {
  searchMsg.value = e
  reqDriverPage()
}
//新增司机
const newAdd = () => {
  fromList.value.forEach( item => {
    item.value = null
  })
  isEdit.value = false
  visible.value = true;
  title.value = '新增司机';
}
//获取表单的值
const getVal = (e) => {
  if (upload1.value?.state.fileLoading) {
    message.warn('请等待图片上传完成')
    return
  }
  let msg = cloneDeep(e)
  let files = []
  upload1.value?.state.fileIds.forEach(item => {
    files.push(item.id)
  })
  msg.fileIds = files
  msg.customerId = props.id
  formListRef.value.fileLoading = true
  if(isEdit.value){
    msg.id = editID.value
    driverEdit(msg).then( res => {
    if(res.code !== 10000) return
     message.success(res.msg)
     reqDriverPage()
      cancel()
    }).finally(() => {
      if( formListRef.value){
        formListRef.value.fileLoading = false
      }
    })
  }else{
    driverAdd(msg).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    reqDriverPage()
    cancel()
  }).finally(() => {
      if( formListRef.value){
        formListRef.value.fileLoading = false
      }
    })
  }
 
  // cancel()
}
const cancel = () => {
  visible.value = false;
}
const reqDriverPage = () => {
  loading.value = true;
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize,
    customerId: props.id
  }
  Object.assign(msg, searchMsg.value)
  driverPage(msg).then(res => {
    if (res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total;
    console.log(res);
  }).finally(() => {
    loading.value = false;
  })
}
onMounted(() => {
  reqDriverPage()
})
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  // height: calc(100vh - 230px);
}
:deep(.ant-upload-picture-card-wrapper) {
  width: auto;
}
</style>