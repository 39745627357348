<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #tail>
        <ly-button @click="newAdd" class="m-l1" type="primary" style="background: #396AFC;border-color:#396AFC" :loading="false">
          <span style="margin-right: 3px;">+</span>新增客户
        </ly-button>
      </template>
    </ly-searchList>
    <div class="m-t1 tableBox">
      <ly-table :columns="columns" ref="tableRef" size="small" rowKey="id" :data="data" :loading="loading" :pagination="pagination" @pageChange="pageChange">
        <template #sort="{index}">
          {{ index+1 }}
        </template>
        <template #name="{ record }">
          <a @click="openItem(record)">{{  record.name  }}</a>
        </template>
        <template #status="{ record }">
          <a-switch size="small" v-model:checked="checked2" />
        </template>
      </ly-table>
    </div>
    <ly-modal v-model:visible="visible" :title="title" :footer="false" width="40%" v-if="visible" :zIndex="1100">
      <ly-twoForm  @changeOpt="changeOpt" ref="twoFormRef" :fromList="fromList" @cancel="cancel" @getVal="getVal">
        <template #custom>
          <div class="m-t1">资料照片<span style="font-size:12px;color:#999">（企业：营业执照；个人：身份证正面、反面）</span></div>
          <div class=" m-t1  pos-rel">
            <ly-upload ref="upload1" text="上传照片"></ly-upload>
          </div>
        </template>
      </ly-twoForm>
    </ly-modal>
    <a-drawer title="" :bodyStyle="{'padding':0}" width="75%" :closable="false" v-model:visible="drawerVis">
      <editContent v-if="drawerVis" :msgInfo="msgInfo" @close="close" @edit="edit"></editContent>
    </a-drawer>
  </div>
</template>
  
  <script setup>
import { nextTick, onMounted, ref, watch } from 'vue'
import { PlusOutlined } from "@ant-design/icons-vue";
import { iptData, columns, fromList } from './crmManagement.js'
import editContent from './editContent.vue';
import { page, customerAdd, customerInfo, customerEdit } from '@/api/crmManagement/crm'
import { message } from 'ant-design-vue';
import { cloneDeep } from 'lodash-es'
import { IfNull } from '@/utils//util'
import { idNumberValidate, enterpriseValidate } from '@/utils/validate'
const upload1 = ref(null)
const visible = ref(false)
const drawerVis = ref(false)
const loading = ref(false)
const data = ref([])
const searchMsg = ref({})
const msgInfo = ref({})
const isEdit = ref(false)
const editID = ref('')
const title = ref('')
const twoFormRef = ref(null)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
//分页事件
const pageChange = (e) => {
  pagination.value = e
  reqPage()
}
const resetFrom = (e) => {
  if (e === 2) {
      fromList.value[8].label = '企业信用代码'
      fromList.value[8].rules[1].pattern = enterpriseValidate
      fromList.value[8].rules[1].message = '请输入正确的企业信用代码'
    } else {
      fromList.value[8].label = '身份证号码'
      fromList.value[8].rules[1].pattern = idNumberValidate
      fromList.value[8].rules[1].message = '请输入正确的身份证号'
    }
}
const changeOpt = (e, index) => {
  if (index == 0) {
    resetFrom(e)
  }
}
//编辑
const edit = (id) => {
  customerInfo(id).then(res => {
    if (res.code !== 10000) return
    isEdit.value = true
    editID.value = id
    let address = res.data.address.province ? [res.data.address.province, res.data.address.city, res.data.address.area] : []
    fromList.value.forEach(item => {
      if (item.flag == 'value') {
        item.value = res.data[item.prop]
      } else if (item.flag == 'lable') {
        item.value = res.data[item.prop].value
      }
      if (item.prop == 'address') {
        item.value = address
      } else if (item.prop == 'details') {
        item.value = res.data.address?.details
      }
      if (item.prop == 'customerType') {
        item.disabled = true
      }
    })
    let e = res.data.customerType.value
    resetFrom(e)
    title.value = '编辑'
    visible.value = true;
  const time = setTimeout(() => {
    upload1.value.state.fileIds = res.data.files ? res.data.files : []
    clearTimeout(time)
  }, 300)
})
}
const close = () => {
  drawerVis.value = false
}
const openItem = (item) => {
  customerInfo(item.id).then(res => {
    if (res.code !== 10000) return
    msgInfo.value = res.data
    drawerVis.value = true
  })

}
// 重置
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqPage()
}
// 搜索事件
const searchAdd = (e) => {
  pagination.value.current = 1
  searchMsg.value = e
  reqPage()
}
//新增客户
const newAdd = () => {
  fromList.value.forEach((e, index) => {
    if (index == 0) {
      e.value = 1
      e.disabled = false
    } else {
      e.value = null
    }
  })
  resetFrom(1)
  isEdit.value = false
  title.value = '新增客户'
  visible.value = true;
}
//获取表单的值
const getVal = (e) => {
  if (upload1.value?.state.fileLoading) {
    message.warn('请等待图片上传完成')
    return
  }
  let msg = cloneDeep(e)
  if (msg.address) {
    if (IfNull(msg.details) && !msg.address[0]) {
      message.error('请选择省份')
      return
    }
    let addInfo = {
      province: msg.address[0] ? msg.address[0] : '',
      city: msg.address[1] ? msg.address[1] : '',
      area: msg.address[2] ? msg.address[2] : '',
      details: msg.details,
    }
    msg.address = addInfo
  }
  twoFormRef.value.fileLoading = true
  let files = []
  upload1.value?.state.fileIds.forEach(item => {
    files.push(item.id)
  })
  msg.fileIds = files
  if (isEdit.value) {
    msg.id = editID.value
    customerEdit(msg).then(res => {
      if (res.code !== 10000) return
      message.success('编辑成功')
      reqPage()
      visible.value = false;
      customerInfo(editID.value).then(res => {
        if (res.code !== 10000) return
        msgInfo.value = res.data
      })
    }).finally(() => {
      twoFormRef.value.fileLoading = false
    })
  } else {
    customerAdd(msg).then(res => {
      if (res.code !== 10000) return
      message.success('添加成功')
      reqPage()
      cancel()
      visible.value = false;
    }).finally(() => {
      twoFormRef.value.fileLoading = false
    })
  }
}
const cancel = () => {
  visible.value = false;
}
const reqPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  page(msg).then(res => {
    if (res.code !== 10000) return
    console.log(res);
    data.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  reqPage()
})
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  height: calc(100vh - 230px);
}
</style>