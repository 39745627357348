import { ref } from 'vue'
import {  phoneValidate,idNumberValidate } from '@/utils/validate'
export const iptData = ref([
  {
    type: 'input',
    placeholder: '客户名称',
    value: '',
    prop: 'name',
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '负责人姓名',
    value: '',
    prop: 'charge',
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '负责人电话',
    value: '',
    prop: 'chargeMobile',
    width: '10%'
  },
  {
    type: 'select',
    placeholder: '客户类型',
    value: null,
    prop: 'businessType',
    width: '10%',
    opt: [
      {
        label: '同行',
        value: 1
      },
      {
        label: '直客',
        value: 2
      }
    ]
  },
  {
    type: 'select',
    placeholder: '客户性质',
    value: null,
    prop: 'customerType',
    width: '10%',
    opt: [
      {
        label: '个人',
        value: 1
      },
      {
        label: '企业',
        value: 2
      }
    ]
  },
  {
    type: 'select',
    placeholder: '结算方式',
    value: null,
    prop: 'settlementMode',
    width: '10%',
    opt: [
      {
        label: '实时',
        value: 1
      },
      {
        label: '记账',
        value: 3
      },
      {
        label: '月结',
        value: 2
      }
    ]
  },
])
export const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    slots: { customRender: 'sort' },
    align: 'center',
    width: '5%'
  },
  {
    title: '姓名',
    dataIndex: 'name',
    align: 'center',
    slots: { customRender: 'name' }
  },
  {
    title: '客户类型',
    dataIndex: 'businessType.label',
    align: 'center'
  },
  {
    title: '客户性质',
    dataIndex: 'customerType.label',
    align: 'center'
  },
  {
    title: '负责人',
    dataIndex: 'charge',
    align: 'center'
  },
  {
    title: '负责人电话',
    dataIndex: 'chargeMobile',
    align: 'center'
  },
  {
    title: '结算方式',
    dataIndex: 'settlementMode.label',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    align: 'center'
  },
])
export const fromList = ref([
  {
    label: '客户性质',
    name: 'customerType',
    type: 'select',
    placeholder: '请选择客户性质',
    value: null,
    prop: 'customerType',
    width: '100%',
    flag:'lable',
    disabled:false,
    rules: [
      {
        required: true,
        message: '请选择客户性质',
        trigger: 'blur',
        type:'number',
      },
    ],
    opt: [
      {
        label: '个人',
        value: 1
      },
      {
        label: '企业',
        value: 2
      }
    ]
  },
  {
    label: '客户类型',
    name: 'businessType',
    type: 'select',
    placeholder: '请选择客户类型',
    value: null,
    prop: 'businessType',
    width: '100%',
    flag:'lable',
    rules: [
      {
        required: true,
        message: '请选择客户类型',
        trigger: 'blur',
        type:'number',
      },
    ],
    opt: [
      {
        label: '同行',
        value: 1
      },
      {
        label: '直客',
        value: 2
      }
    ]
  },
  {
    label: '客户名称',
    name: 'name',
    type: 'input',
    placeholder: '请输入客户名称',
    value: null,
    prop: 'name',
    width: '100%',
    flag:'value',
    rules: [
      {
        required: true,
        message: '请输入客户名称',
        trigger: 'blur'
      },
    ],
  },
  {
    label: '结算方式',
    name: 'settlementMode',
    type: 'select',
    placeholder: '请选择结算方式',
    value: null,
    prop: 'settlementMode',
    width: '100%',
    flag:'lable',
    rules: [
      {
        required: true,
        message: '请选择结算方式',
        trigger: 'blur',
        type:'number',
      },
    ],
    opt: [
      {
        label: '实时',
        value: 1
      },
      {
        label: '记账',
        value: 3
      },
      {
        label: '月结',
        value: 2
      }
    ]
  },
  {
    label: '负责人',
    name: 'charge',
    type: 'input',
    placeholder: '请输入负责人',
    value: null,
    prop: 'charge',
    width: '100%',
    flag:'value',
    rules: [
      {
        required: true,
        message: '请输入负责人',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '负责人电话',
    name: 'chargeMobile',
    type: 'input',
    placeholder: '请输入负责人电话',
    value: null,
    prop: 'chargeMobile',
    width: '100%',
    flag:'value',
    rules: [
      {
        required: true,
        message: '请输入负责人电话',
        trigger: 'blur'
      },
      { pattern: phoneValidate, message: '号码格式不正确', trigger: 'blur' }
    ]
  },
  {
    label: '常用地址',
    name: 'address',
    type: 'address',
    placeholder: '所属区域(省市区)',
    value: null,
    prop: 'address',
    width: '100%',
  },
  {
    label: '详细地址',
    name: 'details',
    type: 'input',
    placeholder: '请输入详细地址',
    value: null,
    prop: 'details',
    width: '100%',
    flag:'value',
  },
  {
    label: '身份证号码',
    name: 'certificateNumber',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'certificateNumber',
    width: '100%',
    flag:'value',
    rules: [
      {
        required: false,
        message: '',
        trigger: 'blur'
      },
      { pattern: idNumberValidate, message: '请输入正确的身份证号', trigger: 'blur' }
    ]
  },
])