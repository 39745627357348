import { ref } from 'vue'
import {  phoneValidate, idNumberValidate } from '@/utils/validate'
export const iptData = ref([
  {
    type: 'input',
    placeholder: '姓名',
    value: '',
    prop: 'name',
    width: '13%'
  },
  {
    type: 'input',
    placeholder: '电话',
    value: '',
    prop: 'mobile',
    width: '13%'
  },
])
export const columns =ref([
  {
    title: '司机姓名',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '司机电话',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '身份证号',
    dataIndex: 'idNumber',
    align: 'center'
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '司机证件图片',
    dataIndex: 'lookImg',
    align: 'center',
    slots: {
      customRender: 'lookImg'
    }
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
])
export const fromList = ref([
  {
    label: '姓名',
    name: 'name',
    type: 'input',
    placeholder: '请输入姓名',
    value: null,
    prop: 'name',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入姓名',
        trigger: 'blur'
      },
     
    ]
  },
  {
    label: '电话',
    name: 'mobile',
    type: 'input',
    placeholder: '请输入电话号码',
    value: null,
    prop: 'mobile',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入电话号码',
        trigger: 'blur'
      },
      { pattern: phoneValidate, message: '号码格式不正确', trigger: 'blur' }
    ]
  },
  {
    label: '身份证号',
    name: 'idNumber',
    type: 'input',
    placeholder: '请输入身份证号',
    value: null,
    prop: 'idNumber',
    width: '100%',
    rules: [
      {
        required: false,
        message: '请输入身份证号',
        trigger: 'blur'
      },
      { pattern: idNumberValidate, message: '证件号码格式不正确', trigger: 'blur' }
    ]
  },
])